import React, {useEffect} from 'react'
import './App.scss';
import {BrowserRouter, Route, Switch} from "react-router-dom"
import DefaultLayout from "./components/global/Layout"
import NotFound from "./pages/Page404"
import {Provider, useDispatch, useSelector} from 'react-redux'
import store from './store/store'
import HomePage from "./pages/Home"
import AppPage from "./pages/App"
import LevelPage from "./pages/Level"
import GamePage from "./pages/Game"
import { HelmetProvider } from 'react-helmet-async';
import Static from "./pages/Static";
import Auth from "./pages/Auth"
import {AuthService} from "./service/authService"
import {setUser} from "./store/actions/auth"
import ProfilePage from "./pages/ProfilePage"
import Login from "./pages/Login"

function WithLayout({ Layout, component, withAuth, ...rest }) {
    const { token } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!!token){
            AuthService.getProfile().then((response) => {
                if(response?.result) {
                    dispatch(setUser(response.user));
                }
            })
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [token])

    if(withAuth && !token) {
        component = Auth
    }

    return (
        <Route {...rest} render={(props) =>  React.createElement( Layout, {withAuth, ...props, className: rest.className},
                component ? React.createElement(component, Object.assign({}, props, rest)): null
            )
        }/>
    );
}

function App() {
  return (
      <HelmetProvider>
          <Provider store={store}>
              <BrowserRouter>
                  <Switch>
                      <WithLayout Layout={DefaultLayout} className="home-page" withAuth={false} exact={true} path={'/'} component={HomePage}/>
                      <WithLayout Layout={DefaultLayout} withAuth={true} exact={true} path={'/app/:professionSlug'} component={AppPage}/>
                      <WithLayout Layout={DefaultLayout} withAuth={true} exact={true} path={'/app/:professionSlug/:appSlug'} component={LevelPage}/>
                      <WithLayout Layout={GamePage} withAuth={true} exact={true} path={'/app/:professionSlug/:appSlug/:gameSlug'} component={null}/>
                      <WithLayout Layout={DefaultLayout} withAuth={true} exact={true} path={'/profile'} component={ProfilePage}/>
                      <WithLayout Layout={DefaultLayout} withAuth={true} exact={true} path={'/login'} component={Login}/>
                      <WithLayout Layout={DefaultLayout} withAuth={false} exact={true} path={'/auth/set-password/:token'} view="set-password" component={Auth}/>
                      <WithLayout Layout={DefaultLayout} withAuth={false} exact={true} path={'/content/:slug'} component={Static}/>
                      <WithLayout Layout={DefaultLayout} withAuth={false} component={NotFound} />
                  </Switch>
              </BrowserRouter>
          </Provider>
      </HelmetProvider>
  );
}

export default App;

import React from "react";

export default () => {
    return (
        <section className="static-page">
            <p>Select the Art Period you want to play, then select your preferred level of play
            and then pick an Artist. The selected Artist's name will show at the top of the screen.
            </p>
            <p>Slide to see all ArtWorks, there are four per Artist; to win you must find all of the selected Artist's
                works. Click on an ArtWork belonging to the selected Artist to move it to a circle at the bottom, if you
                make a mistake you lose a life. If you lose all four lives, you lose the game…. but then you can play
                again, as many times as you want!
            </p>
            <p>We store ONLY your highest score for each artist you play.
            </p>
            <p>Your score for any given game is:
                <br/>- at Easy level of play: 100 minus the number of lives you lost
                times 10, minus the number of Hints times 10
                <br/>- at Medium level of play: 200 minus the number of lives
                    you lost times 20, minus the number of Hints times 20
                <br/>- at Hard level of play: 300 minus the
                        number of lives you lost times 30, minus the number of Hints times 30
            </p>
            <p>If you play with Timer and you win your score will be multiplied by 2, 3 or 4, dependig on level of play;
                if you lose your score for that game is zero.
            </p>
            <p>If you want a hint click on the hint icon under the artwork; it will cost you points but it may save you
                a life.
            </p>
            <p>If you want to enlarge an ArtWork click on the enlarge icon under it; click outside the enlarged image to
                return to the game.
            </p>
            <p style={{ textAlign: 'center'}}>
                Images courtesy of the Web Gallery of Art (<a href="http://www.wga.hu)">http://www.wga.hu</a>).
            </p>
        </section>
    );
}